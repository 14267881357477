<template>
  <div class="billdetail">
    <div class="billdetail_back" @click="backHomepage">
      <van-icon name="arrow-left" />
      <span>返回</span>
    </div>
    <div class="billdetail_content">
      <div class="runserial">
        <span class="runserial-tip">流水号</span>
        <input type="text" v-model="runserial" disabled />
      </div>
      <van-button color="#428bca" class="makeinvoce" @click="toMakeinvoice"
        >开票</van-button
      >
      <div class="goodsdetail">
        <div style="display: flex; justify-content: space-between">
          <div>消费明细</div>
          <div style="font-weight: 700">
            总额：￥<span style="color: red">{{ totalprice }}</span>
          </div>
        </div>
        <table class="table">
          <thead>
            <tr>
              <th>品名</th>
              <th>金额</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in consumeList" :key="index">
              <td>{{ item.goodsname }}</td>
              <td>￥{{ item.quantity }}*{{ item.unitprice }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      runserial: '',
      consumeList: [],
      mobile: '',
      tradeno: '',
      amount: '',
      totalprice: ''
    }
  },
  created() {
    this.mobile = localStorage.getItem('mobile')
    this.tradeno = this.$route.query.tradeno
    this.amount = this.$route.query.amount
    this.$axios
      .get(
        `outbill?mobile=${this.mobile}&tradeno=${this.tradeno}&amount=${this.amount}`
      )
      .then((res) => {
        if (res.data.retcode == '0000') {
          this.runserial = res.data.runserial
          this.consumeList = res.data.data
          let singleprice = 0
          res.data.data.forEach((item) => {
            singleprice = singleprice + item.amount
          })
          this.totalprice = singleprice
        } else {
          this.$dialog
            .alert({
              message: res.data.retmessage
            })
            .then(() => {
              // on close
            })
        }
      })
  },
  methods: {
    toMakeinvoice() {
      if (this.runserial) {
        this.$router.push({
          path: '/makeinvoice',
          query: { runserial: this.runserial, mobile: this.mobile }
        })
      } else {
        this.$dialog
          .alert({
            message: '流水号不能为空'
          })
          .then(() => {
            // on close
          })
      }
    },
    backHomepage() {
      this.$router.push('/index')
    }
  }
}
</script>
<style scoped>
.billdetail .billdetail_back {
  height: 50px;
  line-height: 50px;
  font-size: 18px;
  color: #428bca;
}
.billdetail .billdetail_content {
  border: 1px solid #bce8f1;
  margin: 0 1px 15px 1px;
}
.billdetail .runserial {
  padding: 15px;
  width: 95%;
  text-align: center;
}
.billdetail .runserial .runserial-tip {
  display: inline-block;
  font-weight: 700;
  margin-right: 15px;
}
.billdetail .runserial input {
  width: 60%;
  height: 30px;
}
.billdetail .makeinvoce {
  width: 70%;
  display: block;
  margin: auto;
  margin-bottom: 15px;
}
.billdetail .goodsdetail {
  padding: 10px 15px;
  background: #f5f5f5;
  border-top: 1px solid #ddd;
}
.billdetail .goodsdetail .table {
  margin-top: 15px;
}
</style>
